<template>
  <div class="ModifyOrArchiveDevis">
    <CModal
      :show.sync="isDevisModified"
      :no-close-on-backdrop="true"
      title="Devis modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre devis a bien été modifié 👍
      </p>
      <p>
        Vous avez bien modifié votre devis. Vous pouvez continuer les modifications ou retourner à la liste des devis de votre cabinet.
      </p>
      <template #footer>
        <CButton @click="isDevisModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/manager-dashboard-devis/')" color="dark">Retour à la liste des devis</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isDevisArchived"
      :no-close-on-backdrop="true"
      title="Devis archivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre devis a bien été supprimé ⛔
      </p>
      <p>
        Cette devis n'apparaîtra plus dans la liste des devis de votre cabinet.
      </p>
      <template #footer>
        <CButton @click="$router.push('/manager-dashboard-devis/')" color="primary">Retour à la liste des devis</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isDevisSent"
      :no-close-on-backdrop="true"
      title="Devis envoyée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre devis a bien été envoyé 👍
      </p>
      <p>
        Votre devis a bien été envoyé à votre client.<br>
        <small> Si celui-ci ne la reçoit pas, n'oubliez pas de lui conseiller de vérifier dans ses spams</small>
      </p>
      <template #footer>
        <CButton @click="isDevisSent = false" color="primary">Fermer</CButton>
      </template>
    </CModal>


    <!-- SEND DEVIS BY EMAIL -->
    <CModal
      :show.sync="isSendingDevisByEmail"
      :no-close-on-backdrop="true"
      title="Envoyer votre devis par email"
      size="lg"
      color="dark"
    >
      <p>
        Vous pouvez directement envoyer votre devis par email à votre client 💌
      </p>

      <CForm>

        <CInput
          v-model="sendDevisClientEmail" type="email"
          label="Email de votre client"
          placeholder="Email de votre client*"
          @input="$v.sendDevisClientEmail.$touch()"
          :isValid="$v.sendDevisClientEmail.$dirty ? !$v.sendDevisClientEmail.$error : null"
          invalid-feedback="Veuillez entrer un email au bon format"
        >
        </CInput>

        <CTextarea
          placeholder="Message personnalisé"
          label="Message personnalisé (facultatif)"
          type="text"
          max="500"
          rows="5"
          v-model="sendDevisCustomizedMessage"
          @input="$v.sendDevisCustomizedMessage.$touch()"
          :isValid="$v.sendDevisCustomizedMessage.$dirty ? !$v.sendDevisCustomizedMessage.$error : null">
        </CTextarea>

        <CInput
          v-model="sendDevisAnswerEmail" type="email"
          label="Email sur lequel votre client peut répondre"
          placeholder="Email de réponse*"
          @input="$v.sendDevisAnswerEmail.$touch()"
          :isValid="$v.sendDevisAnswerEmail.$dirty ? !$v.sendDevisAnswerEmail.$error : null"
          invalid-feedback="Veuillez entrer un email au bon format"
        >
        </CInput>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="sendDevisEmailButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.sendDevisClientEmail.$invalid || $v.sendDevisCustomizedMessage.$invalid || $v.sendDevisAnswerEmail.$invalid || sendDevisEmailInProcess"
              @click='sendDevisEmail'>
              {{ sendDevisEmailButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isSendingDevisByEmail = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- MODIFY UNTE DEVIS -->
    <CModal
      :show.sync="isUniteDevisModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre item"
      size="lg"
      color="dark"
    >
      <CForm>

        <CInput
          v-model="modifyUniteDevisDate" type="date"
          label="Date"
          placeholder="AAAA-MM-DD"
          @input="$v.modifyUniteDevisDate.$touch()"
          :isValid="$v.modifyUniteDevisDate.$dirty ? !$v.modifyUniteDevisDate.$error : null"
          invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
        >
          <template #description>
            <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
              <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
            </div>
            <small class="text-danger" v-if="!$v.modifyUniteDevisDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
          </template>
        </CInput>

        <CTextarea
          placeholder="Description"
          label="Description"
          type="text"
          max="1000"
          rows="5"
          v-model="modifyUniteDevisDescription"
          @input="$v.modifyUniteDevisDescription.$touch()"
          :isValid="$v.modifyUniteDevisDescription.$dirty ? !$v.modifyUniteDevisDescription.$error : null">
        </CTextarea>


        <CRow>
          <CCol sm="6">
            <CInput
              placeholder="Quantité"
              label="Quantité"
              type="number"
              v-model="modifyUniteDevisQuantite"
              @input="$v.modifyUniteDevisQuantite.$touch()"
              :isValid="$v.modifyUniteDevisQuantite.$dirty ? !$v.modifyUniteDevisQuantite.$error : null"
              min="0" max="100000000" step=".01" @blur="modifyUniteDevisQuantite = parseFloat(modifyUniteDevisQuantite).toFixed(2)">
            </CInput>
          </CCol>
          <CCol sm="6">
            <CInput
              placeholder="Prix unitaire"
              label="Prix unitaire (en € HT)"
              type="number"
              v-model="modifyUniteDevisPrixUnitaire"
              @input="$v.modifyUniteDevisPrixUnitaire.$touch()"
              :isValid="$v.modifyUniteDevisPrixUnitaire.$dirty ? !$v.modifyUniteDevisPrixUnitaire.$error : null"
              min="0" max="100000000" step=".01" @blur="modifyUniteDevisPrixUnitaire = parseFloat(modifyUniteDevisPrixUnitaire).toFixed(2)">
            </CInput>
          </CCol>
        </CRow>

        <CInput
          placeholder="Remise HT"
          label="Remise tarifaire (en € HT)"
          type="number"
          v-model="modifyUniteDevisRemiseHT"
          @input="$v.modifyUniteDevisRemiseHT.$touch()"
          :isValid="$v.modifyUniteDevisRemiseHT.$dirty ? !$v.modifyUniteDevisRemiseHT.$error : null"
          min="0" max="100000000" step=".01" @blur="modifyUniteDevisRemiseHT = parseFloat(modifyUniteDevisRemiseHT).toFixed(2)">
        </CInput>

        <CSelect
          label="TVA"
          :value.sync="modifyUniteDevisTVA"
          :options="tvaArrayOptions"
          @input="$v.modifyUniteDevisTVA.$touch()"
          :isValid="$v.modifyUniteDevisTVA.$dirty ? !$v.modifyUniteDevisTVA.$error : null"
          custom
        />


        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyUniteDevisButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyUniteDevisDate.$invalid || $v.modifyUniteDevisDescription.$invalid || $v.modifyUniteDevisQuantite.$invalid || $v.modifyUniteDevisPrixUnitaire.$invalid || $v.modifyUniteDevisRemiseHT.$invalid || $v.modifyUniteDevisTVA$invalid || modifyUniteDevisInProcess"
              @click='modifyUniteDevis'>
              <CSpinner size="sm" label="update task spinner" v-if="modifyUniteDevisInProcess"></CSpinner>
              {{ modifyUniteDevisButtonText }}
            </CButton>
          </CCol>


        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isUniteDevisModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- TURN DEVIS INTO FACTURE -->
    <CModal
      :show.sync="isTurningDevisIntoFacture"
      :no-close-on-backdrop="true"
      title="Générer une facture à partir de votre devis"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          <CSelect
            :value.sync="missionChosen"
            label="Mission associée à la facture"
            :options="missionsSelectOptions"
            @input="$v.missionChosen.$touch()"
            :isValid="$v.missionChosen.$dirty ? !$v.missionChosen.$error : null"
            custom
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          ⚠️ Le contenu de votre facture sera également généré à partir de votre devis. Vous pourrez
          bien évidement le modifier avant d'émettre votre facture
        </CCol>
      </CRow>


      <CButton
        color="outline-primary"
        shape="pill" block class="mt-3 px-4"
        @click="$router.push('/create-facture-from-devis/'+devis.id+'/'+missionChosen+'/'+devis.title+'/'+devis.facturation_address)"
        :disabled="$v.missionChosen.$invalid">
        Générer votre facture
      </CButton>

      <template #footer>
        <CButton @click="isTurningDevisIntoFacture = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol sm="6">
        <h1> Devis #{{devis.number}} - {{devis.client.name}} </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="getClientAllMissions(); isTurningDevisIntoFacture = true"
          shape="pill"
          color="outline-success">
            <CIcon name="cil-plus"/> Générer une facture à partir de ce devis
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol lg="3">
            <span> # Devis : </span> <strong> {{devis.number}}</strong><br>
            <span> Client : </span> <strong> {{devis.client.name}} </strong><br>
            <span> Intitulé : </span> <strong> {{devis.title}} </strong>
          </CCol>
          <CCol lg="9">
            <CRow class="mt-2 mt-lg-0">
              <CCol class="text-lg-right text-center" sm="6">
                <span> MONTANT HT </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} €</strong>
                <strong v-if="parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2) < 0" class="text-danger"><br>Attention, votre montant total est négatif</strong>
              </CCol>

              <CCol class="text-lg-right text-center" sm="6">
                <span> MONTANT TTC </span><br>
                <strong style="font-size: 2em;"> {{formatThousandSeparatorNumber(parseFloat(totalTTC).toFixed(2))}} €</strong>
              </CCol>
            </CRow>
          </CCol>

        </CRow>
        <CRow v-if="parseFloat(sousTotalHT) == 0" class="mt-2">
          <CCol class="text-right">
            <strong class="text-danger">Veuillez renseigner des items pour votre devis</strong>
          </CCol>
        </CRow>

        <div v-if="parseFloat(sousTotalHT) != 0">
          <CRow  class="mt-4">
            <CCol v-if="!devis.has_been_sent" md="4" class="align-self-center">
              <CInputCheckbox
                  label="Marquer votre devis comme envoyé au client"
                  :custom="true"
                  name="hasBeenSentDevisCheckbox"
                  :inline="true"
                  @click="markDevisAsSent()"
                />
            </CCol>
            <CCol v-else md="4" class="align-self-center">
              <strong>💌 <em>Devis envoyée au client</em> </strong>
            </CCol>

            <CCol md="8" class="mt-2 mt-md-0 text-md-right align-self-center">
              <CButton shape="pill" color="outline-primary" @click="downloadDevis()"> <CIcon name="cil-cloud-download" /> Télécharger votre devis en pdf </CButton>
              <br class="d-sm-block d-md-none">
              <CButton class="ml-0 ml-md-2 mt-2 mt-md-0" shape="pill" color="outline-primary" @click="isSendingDevisByEmail=true"> <CIcon name="cil-send" /> Envoyer votre devis par email </CButton>
            </CCol>
          </CRow>

        </div>
      </CCardBody>
    </CCard>



      <CCard class="mt-3">
        <CCardHeader
          @click="cardDevisCaracteristicsCollapse = !cardDevisCaracteristicsCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>📃 Caractéristiques de votre devis</strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardDevisCaracteristicsCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardDevisCaracteristicsCollapse">
          <CCardBody>
            <CForm>
              <p>
                <span>
                  Exercice comptable :
                  <strong>{{devis.exercice_comptable}}</strong>
                </span>
              </p>
              <CInput
                label="Intitulé de votre devis"
                v-model="modifyDevisTitle" type="text"
                placeholder="Renseignez l'intitulé de votre devis"
                maxlength="149"
                :disabled="!devis.is_active"
                @input="$v.modifyDevisTitle.$touch()"
                :isValid="$v.modifyDevisTitle.$dirty ? !$v.modifyDevisTitle.$error : null"
              >
              </CInput>

              <CInput
                class="mt-3"
                label="Adresse"
                v-model="modifyDevisAddress" type="text"
                placeholder="Renseignez l'adresse pour votre devis"
                maxlength="300"
                :disabled="!devis.is_active"
                @input="$v.modifyDevisAddress.$touch()"
                :isValid="$v.modifyDevisAddress.$dirty ? !$v.modifyDevisAddress.$error : null"
              >
              </CInput>


              <CRow>
                <CCol>
                  <div v-if="$browserDetect.isSafari">
                    <b-form-group id="modify-devis-date-group" label="Date" label-for="modify-devis-date-input">
                      <b-form-datepicker
                        id="modify-devis-date-input"
                        name="modify-devis-date-input"
                        locale="fr"
                        :show-decade-nav="true"
                        :disabled="!devis.is_active"
                        placeholder="Choisissez une date"
                        v-model="modifyDevisDate" class="mb-2"
                        @input="$v.modifyDevisDate.$touch()"
                        :state="$v.modifyDevisDate.$dirty ? !$v.modifyDevisDate.$error : null">
                      </b-form-datepicker>
                      <small class="text-danger" v-if="!$v.modifyDevisDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </b-form-group>
                  </div>
                  <div v-else>
                    <CInput
                      label="Date"
                      v-model="modifyDevisDate" type="date"
                      placeholder="AAAA-MM-DD"
                      :disabled="!devis.is_active"
                      @input="$v.modifyDevisDate.$touch()"
                      :isValid="$v.modifyDevisDate.$dirty ? !$v.modifyDevisDate.$error : null"
                      invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                    >
                    <template #description>
                      <div v-if="$browserDetect.isIE" class="text-info">
                        <small>Vous utilisez Internet Explorer, veuillez entrer votre
                        date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                      </div>
                      <small class="text-danger" v-if="!$v.modifyDevisDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                    </template>
                    </CInput>
                  </div>

                </CCol>
              </CRow>

              <CTextarea
                class="mt-2"
                label="Message sur le devis"
                v-model="modifyDevisMessageDevis" type="text"
                placeholder="Vous pouvez renseigner un message personnalisé sur le devis"
                maxlength="300"
                :disabled="!devis.is_active"
                @input="$v.modifyDevisMessageDevis.$touch()"
                :isValid="$v.modifyDevisMessageDevis.$dirty ? !$v.modifyDevisMessageDevis.$error : null"
              >
              </CTextarea>
              <CRow>
                <CCol>
                  <div v-if="devis.is_active">
                    <CRow class="mt-2">
                      <CCol :sm="{size: 3, offset: 6}">
                        <CButton
                          :color="modifyDevisButtonStyle"
                          shape="pill" block class="px-4" size="sm"
                          @click='modifyDevis'
                          :disabled="$v.modifyDevisTitle.$invalid || $v.modifyDevisAddress.$invalid || $v.modifyDevisDate.$invalid || $v.modifyDevisMessageDevis.$invalid || modifyDevisInProcess">
                          <CSpinner size="sm" label="modify devis spinner" v-if="modifyDevisInProcess"></CSpinner>
                          {{ modifyDevisButtonText }}
                        </CButton>
                      </CCol>
                      <CCol :sm="{size: 3}">
                        <CButton
                          :color="deleteDevisButtonStyle"
                          shape="pill" block class="px-4" size="sm"
                          @click='deleteDevis'
                          :disabled="deleteDevisInProcess">
                          <CSpinner size="sm" label="delete devis spinner" v-if="deleteDevisInProcess"></CSpinner>
                          {{ deleteDevisButtonText }}
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCollapse>
    </CCard>


    <CCard class="mt-3">
        <CCardHeader
          @click="cardDevisContenuCollapse = !cardDevisContenuCollapse"
          style="padding-bottom: 15px !important; cursor: pointer;"
        >
          <strong>📝 Contenu de votre devis -
            <span v-if="parseFloat(sousTotalHT) == 0" class="text-danger">❌ A renseigner</span>
            <span v-else> Total de {{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} € HT </span>
          </strong>
          <div class="card-header-actions">
            <CIcon v-if="!cardDevisContenuCollapse" name="cil-arrow-circle-bottom" size="lg"/>
            <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
          </div>
        </CCardHeader>
        <CCollapse :show="cardDevisContenuCollapse">
          <CCardBody>
            <CRow>
              <CCol>
                <strong>
                  Ajouter ci-dessous les items de votre devis
                </strong> (chaque item correspond à une ligne de votre devis).
                <b-form
                  v-on:submit.prevent="addUniteDevis(newUniteDevis.date, newUniteDevis.description, newUniteDevis.quantite, newUniteDevis.prix_unitaire, newUniteDevis.remise_ht, newUniteDevis.tva)">
                  <CDataTable
                    addTableClasses="no-border-table no-margin-bottom-table"
                    :header="true"
                    headVariant="light"
                    :items="[newUniteDevis]"
                    :fields="newUniteDevisRenderFields"
                  >
                    <template #date="">
                      <td>
                        <CInput
                          v-model="newUniteDevis.date" type="date"
                          placeholder="Date (AAAA-MM-DD)"
                          @input="$v.newUniteDevis.date.$touch()"
                          :isValid="$v.newUniteDevis.date.$dirty ? !$v.newUniteDevis.date.$error : null"
                          invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                          :disabled="!devis.is_active"
                        />
                      </td>
                    </template>
                    <template #description="">
                      <td v-if="isUniteDevisAddingBigDescription == false" style="min-width:350px">
                        <CInput
                          style="margin-bottom: 0px;"
                          placeholder="Description"
                          type="text"
                          maxlength="150"
                          v-model="newUniteDevis.description"
                          @input="$v.newUniteDevis.description.$touch()"
                          :isValid="$v.newUniteDevis.description.$dirty ? !$v.newUniteDevis.description.$error : null"
                          :disabled="!devis.is_active">
                        </CInput>
                        <CButton @click="isUniteDevisAddingBigDescription = true" size="sm" color="outline-primary" class="mt-2" :disabled="!devis.is_active">
                          Ajouter une description sur plusieurs lignes
                        </CButton>
                      </td>
                      <td v-else style="min-width:350px">
                        <CTextarea
                          placeholder="Description"
                          type="text"
                          maxlength="1000"
                          rows="5"
                          v-model="newUniteDevis.description"
                          @input="$v.newUniteDevis.description.$touch()"
                          :isValid="$v.newUniteDevis.description.$dirty ? !$v.newUniteDevis.description.$error : null"
                          :disabled="!devis.is_active">
                        </CTextarea>
                      </td>
                    </template>
                    <template #quantite="">
                      <td>
                        <CInput
                          placeholder="Quantité"
                          type="number"
                          v-model="newUniteDevis.quantite"
                          @input="$v.newUniteDevis.quantite.$touch()"
                          :isValid="$v.newUniteDevis.quantite.$dirty ? !$v.newUniteDevis.quantite.$error : null"
                          min="0" max="100000000" step=".01" @blur="newUniteDevis.quantite = parseFloat(newUniteDevis.quantite).toFixed(2)"
                          :disabled="!devis.is_active">
                        </CInput>
                      </td>
                    </template>
                    <template #prix_unitaire="">
                      <td>
                        <CInput
                          placeholder="Prix unitaire (en € HT)"
                          type="number"
                          v-model="newUniteDevis.prix_unitaire"
                          @input="$v.newUniteDevis.prix_unitaire.$touch()"
                          :isValid="$v.newUniteDevis.prix_unitaire.$dirty ? !$v.newUniteDevis.prix_unitaire.$error : null"
                          min="0" max="100000000" step=".01" @blur="newUniteDevis.prix_unitaire = parseFloat(newUniteDevis.prix_unitaire).toFixed(2)"
                          :disabled="!devis.is_active">
                        </CInput>
                      </td>
                    </template>
                    <template #remise_ht="">
                      <td>
                        <CInput
                          placeholder="Remise tarifaire (en € HT)"
                          type="number"
                          v-model="newUniteDevis.remise_ht"
                          @input="$v.newUniteDevis.remise_ht.$touch()"
                          :isValid="$v.newUniteDevis.remise_ht.$dirty ? !$v.newUniteDevis.remise_ht.$error : null"
                          min="0" max="100000000" step=".01" @blur="newUniteDevis.remise_ht = parseFloat(newUniteDevis.remise_ht).toFixed(2)"
                          :disabled="!devis.is_active">
                        </CInput>
                      </td>
                    </template>
                    <template #tva="">
                      <td>
                        <CSelect
                          :value.sync="newUniteDevis.tva"
                          :options="tvaArrayOptions"
                          @input="$v.newUniteDevis.tva.$touch()"
                          :isValid="$v.newUniteDevis.tva.$dirty ? !$v.newUniteDevis.tva.$error : null"
                          :disabled="!devis.is_active"
                          custom
                        />
                      </td>
                    </template>
                    <template #actions="">
                      <td>
                        <CButton
                          type="submit"
                          :color="addUniteDevisButtonStyle"
                          square
                          :disabled="!devis.is_active || $v.newUniteDevis.$invalid || addUniteDevisInProcess"
                        >
                            {{addUniteDevisButtonText}}
                        </CButton>
                      </td>
                    </template>
                  </CDataTable>
                </b-form>
              </CCol>
            </CRow>

            <CRow v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
              <CCol>
                <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                <small class="text-danger" v-if="!$v.newUniteDevis.date.validDate"><br>Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol>
                <CDataTable
                  headVariant="light"
                  :items="uniteDevisRender"
                  :fields="uniteDevisRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >

                <template #date="{item}">
                  <td>
                    {{$dayjs(item.date).format('DD/MM/YYYY')}}
                  </td>
                </template>

                <template #description="{item}">
                  <td style="white-space: pre-line;"><small>{{item.description}}</small></td>
                </template>

                <template #quantite="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.quantite).toFixed(2))}}
                  </td>
                </template>

                <template #prix_unitaire="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.prix_unitaire).toFixed(2))}} €
                  </td>
                </template>

                <template #montant_total_ht="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ht).toFixed(2))}} €
                  </td>
                </template>

                <template #remise_ht="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.remise_ht).toFixed(2))}} €
                  </td>
                </template>

                <template #tva="{item}">
                  <td>
                    {{tvaRender(item.tva)}}
                  </td>
                </template>

                <template #montant_total_ttc="{item}">
                  <td>
                    {{formatThousandSeparatorNumber(parseFloat(item.montant_total_ttc).toFixed(2))}} €
                  </td>
                </template>

                <template #actions="{item}">
                  <td>
                    <CButtonGroup>
                      <CButton
                        class="ml-1"
                        square
                        v-c-tooltip="{content: 'Modifier',placement: 'top'}"
                        :disabled="!devis.is_active"
                        @click="showModifyUniteDevis(item)">
                        <CIcon class="text-primary" name="cil-pencil"/>
                      </CButton>
                      <CButton
                        class="ml-1"
                        square
                        v-c-tooltip="{content: 'Supprimer',placement: 'top'}"
                        :disabled="!devis.is_active || deleteUniteDevisInProcess"
                        @click="deleteUniteDevis(item.id)">
                        <CIcon class="text-danger" name="cil-trash"/>
                      </CButton>

                    </CButtonGroup>

                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Veuillez enregistrer des items de facturation
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-right" style="font-size: 1.4em;">
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Sous-total HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(sousTotalHT).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Remise globale HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(remiseTotaleHT).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Total net HT</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(parseFloat(sousTotalHT).toFixed(2) - parseFloat(remiseTotaleHT).toFixed(2)).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
                <CRow class="mt-2">
                  <CCol sm="10">
                    <strong> Total TTC</strong>
                  </CCol>
                  <CCol sm="2">
                    <strong>{{formatThousandSeparatorNumber(parseFloat(totalTTC).toFixed(2))}} €</strong>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCollapse>
    </CCard>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue, maxLength, minLength, email, integer } from 'vuelidate/lib/validators'
import renderMixins from '@/mixins/renderMixins'
import { timeOperations } from '@/mixins/utilMixins'
import userMixins from '@/mixins/userMixins'

import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'

import { isDate } from '@/validators/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()

export default {
  name: 'ModifyOrArchiveDevis',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    renderMixins,
    timeOperations,
    userMixins
  ],
  data: function () {
    const today = this.$dayjs().format('YYYY-MM-DD')
    return {

      cardDevisCaracteristicsCollapse: false,
      cardDevisContenuCollapse: true,

      // ----------- GETTERS -------------
      isDevisLoading: false,
      devis: {
        id: 0,
        exercice_comptable: 2020,
        number: '',
        title: '',
        facturation_address: '',
        date: '',
        message: '',
        is_active: true,
        has_been_sent: false,
        client: {
          collaborateur: {
            id: '',
            full_name: ''
          },
          is_domiciliation: false,
          address: '',
          email: '',
          id: '',
          is_firm: true,
          name: '',
          first_name: '',
          last_name: '',
          phone_country_code: '',
          phone_number: '',
          representant_legal: '',
          siren: '',
          is_different_payeur: false,
          is_payeur_firm: true,
          payeur_siren: '',
          payeur_representant_legal: '',
          payeur_name: '',
          payeur_address: '',
          user: null
        },
      },

      // ---------------- MODIFY DEVIS ------------
      isDevisModified: false,
      modifyDevisTitle: '',
      modifyDevisAddress: '',
      modifyDevisDate: '',
      modifyDevisMessageDevis: '',

      modifyDevisButtonText: 'Modifier ce devis',
      modifyDevisButtonStyle: 'outline-primary',
      modifyDevisInProcess: false,

      // ---------------- MARK DEVIS AS SENT -------
      markDevisAsSentInProcess: false,

      // ---------------- ARCHIVE DEVIS ------------
      deleteDevisInProcess: false,
      deleteDevisButtonText: "Archiver ce devis",
      deleteDevisButtonStyle: 'outline-danger',
      isDevisArchived: false,

      // ----------------- SEND DEVIS BY EMAIL ---------
      isSendingDevisByEmail: false,
      sendDevisClientEmail: '',
      sendDevisCustomizedMessage: '',
      sendDevisAnswerEmail: '',

      sendDevisEmailInProcess: false,
      sendDevisEmailButtonStyle: 'outline-primary',
      sendDevisEmailButtonText: 'Envoyer votre devis',

      isDevisSent: false,

      // ---------------- UNITES DEVISS ------------
      uniteDevis: [],
      uniteDevisRender: [],
      uniteDevisRenderFields: [
        { key: "actions", label: "", _style: "min-width: 100px; font-size: 14px"},
        { key: "date", label: "Date", _style: "min-width: 50px; font-size: 14px"},
        { key: "description", label: "Description", _style: "min-width: 200px; font-size: 14px"},
        { key: "quantite", label: "Quantité", _style: "min-width: 100px; font-size: 14px"},
        { key: "prix_unitaire", label: "Prix unitaire HT", _style: "min-width: 150px; font-size: 14px"},
        { key: "montant_total_ht", label: "Total HT", _style: "min-width: 150px; font-size: 14px;"},
        { key: "remise_ht", label: "Remise HT", _style: "min-width: 150px; font-size: 14px"},
        { key: "tva", label: "TVA", _style: "min-width: 100px; font-size: 14px"},
        { key: "montant_total_ttc", label: "Total TTC", _style: "min-width: 150px; font-size: 14px"},
      ],
      isUniteDevisLoading: false,

      // ------------ ADD UNITE DEVIS --------
      newUniteDevisRenderFields: [
        { key: "date", label: "", _style: "min-width: 100px;"},
        { key: "description", label: "", _style: "min-width: 200px;"},
        { key: "quantite", label: "", _style: "min-width: 150px;"},
        { key: "prix_unitaire", label: "", _style: "min-width: 150px;"},
        { key: "remise_ht", label: "", _style: "min-width: 150px;"},
        { key: "tva", label: "", _style: "min-width: 150px;"},
        { key: "actions", label: "", _style: "min-width: 100px;"},
      ],
      newUniteDevis: {
        'date': today,
        'description': '',
        'quantite': 1,
        'prix_unitaire': '',
        'remise_ht': 0,
        'tva': 20
      },

      isUniteDevisAddingBigDescription: false,

      tvaArrayOptions: [
        { value: '20.0', label: '20% TVA FR' },
        { value: '10.0', label: '10% TVA FR' },
        { value: '5.5', label: '5,5% TVA FR' },
        { value: '2.1', label: '2,1% TVA FR' },
        { value: '0.9', label: '0,9% TVA FR' },
        { value: '0.0', label: 'Pas de TVA FR' },
      ],

      addUniteDevisButtonText: 'Ajouter',
      addUniteDevisButtonStyle: 'dark',
      addUniteDevisInProcess: false,

      // ---------- MODIFY UNTIES DEVISS ---
      isUniteDevisModifying: false,

      modifyUniteDevisId: 0,
      modifyUniteDevisDate: '',
      modifyUniteDevisDescription: '',
      modifyUniteDevisQuantite: '',
      modifyUniteDevisPrixUnitaire: '',
      modifyUniteDevisRemiseHT: '',
      modifyUniteDevisTVA: '',

      modifyUniteDevisButtonText: 'Modifier',
      modifyUniteDevisButtonStyle: 'outline-primary',
      modifyUniteDevisInProcess: false,

      deleteUniteDevisButtonText: 'Supprimer',
      deleteUniteDevisButtonStyle: 'outline-danger',
      deleteUniteDevisInProcess: false,

      // ---------- CONVERT DEVIS INTO FACTURE -------
      isTurningDevisIntoFacture: false,

      isAllMissionsLoading: false,
      missionsSelectOptions: [{label: '', value: ''}],
      missionChosen: '',

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDevisLoading || this.isUniteDevisLoading || this.markDevisAsSentInProcess || this.isAllMissionsLoading) {
        return true
      }
      return false
    },
    exerciceComptableYears () {
      var years = [{'label': '', 'value': ''}]
      for (var i=this.devis.exercice_comptable-2; i<=this.devis.exercice_comptable+2; i++) {
        var value = String(i)
        years.push({'label': value, 'value': i})
      }
      return years
    },
    sousTotalHT () {
      var total = 0
      for (var i=0; i < this.uniteDevis.length; i++) {
        total = total + this.uniteDevis[i].quantite * this.uniteDevis[i].prix_unitaire
      }
      return total
    },
    remiseTotaleHT () {
      var total = 0
      for (var i=0; i < this.uniteDevis.length; i++) {
        total = total + parseFloat(this.uniteDevis[i].remise_ht)
      }
      return total
    },
    totalTTC () {
      var total = 0
      for (var i=0; i < this.uniteDevis.length; i++) {
        var sousTotalHT = this.uniteDevis[i].quantite * this.uniteDevis[i].prix_unitaire
        var remiseHT = parseFloat(this.uniteDevis[i].remise_ht)
        var tva = this.uniteDevis[i].tva * (sousTotalHT - remiseHT) / 100
        total = total +  sousTotalHT - remiseHT + tva
      }
      return total
    },

  },
  validations: {

    // ---------- MODIFY DEVISS ------------
    modifyDevisTitle: {
      required,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
    modifyDevisAddress: {
      required,
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
    modifyDevisDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyDevisMessageDevis: {
      maxLength: maxLength(300),
      minLength: minLength(2)
    },

    // --------- SEND DEVIS EMAIL ---------------
    sendDevisClientEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2),
      required
    },
    sendDevisCustomizedMessage: {
      maxLength: maxLength(499),
      minLength: minLength(2)
    },
    sendDevisAnswerEmail: {
      email,
      maxLength: maxLength(249),
      minLength: minLength(2),
      required
    },

    // ---------- A NEW UNITE DEVISS ------------
    newUniteDevis: {
      date: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(1000)
      },
      quantite: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1000000)
      },
      prix_unitaire: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      remise_ht: {
        minValue: minValue(0),
        maxValue: maxValue(100000000)
      },
      tva: {
        required,
      },
    },


    // ---------- MODIFY UNITE DEVISS ------------

    modifyUniteDevisDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyUniteDevisDescription: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(1000)
    },
    modifyUniteDevisQuantite: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(1000000)
    },
    modifyUniteDevisPrixUnitaire: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(100000000)
    },
    modifyUniteDevisRemiseHT: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(100000000)
    },
    modifyUniteDevisTVA: {
      required,
    },

    // -------- CONVERT A DEVIS TO FACTURE
    missionChosen: {
      required,
      minValue: minValue(1),
      integer
    },

  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getDevis()
    this.getAllDevisUnitesDevis()
  },
  watch: {
    uniteDevis: function (newUniteDevis) {
      if (newUniteDevis.length == 0) {
        this.uniteDevisRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < newUniteDevis.length; i++) {

          var sousTotalHT = newUniteDevis[i].quantite * newUniteDevis[i].prix_unitaire
          var remiseHT = parseFloat(newUniteDevis[i].remise_ht)
          var tva = newUniteDevis[i].tva * (sousTotalHT - remiseHT) / 100
          var montantTotalTTC = sousTotalHT - remiseHT + tva

          final_array.push(
            {
              'id': newUniteDevis[i].id,
              'date': newUniteDevis[i].date,
              'description': newUniteDevis[i].description,
              'quantite': newUniteDevis[i].quantite,
              'prix_unitaire': newUniteDevis[i].prix_unitaire,
              'montant_total_ht': sousTotalHT,
              'remise_ht': newUniteDevis[i].remise_ht,
              'tva': newUniteDevis[i].tva,
              'montant_total_ttc': montantTotalTTC
            }
          )
        }
        this.uniteDevisRender = final_array
      }
    },
  },


  methods: {
    // ------------- Getters --------------
    getDevis () {
      this.isDevisLoading = true
      apiManagerConnected.getDevis(this.token, this.$route.params.devis_pk)
      .then((result) => {
        this.devis = result.data
        this.modifyDevisTitle = this.devis.title
        this.modifyDevisAddress = this.devis.facturation_address
        this.modifyDevisDate = this.devis.date
        this.modifyDevisMessageDevis = this.devis.message

        this.sendDevisClientEmail = this.devis.client.email
        this.sendDevisAnswerEmail = this.devis.client.collaborateur.user.username
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDevisLoading = false
      })
    },

    getAllDevisUnitesDevis () {
      this.isUniteDevisLoading = true
      apiManagerConnected.getAllDevisUnitesDevis(this.token, this.$route.params.devis_pk)
      .then((result) => {
        this.uniteDevis = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isUniteDevisLoading = false
      })
    },

    downloadDevis () {
      apiManagerConnected.downloadDevis(this.token, this.$route.params.devis_pk)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const devisName = 'Devis-' + this.devis.exercice_comptable + '-' + this.devis.number + '-' + this.devis.client.name  + '-' + this.devis.date + '.pdf'
          link.setAttribute('download', devisName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },

    sendDevisEmail () {
      this.sendDevisEmailInProcess = true
      apiManagerConnected.sendDevisToClient(this.token, this.$route.params.devis_pk, this.sendDevisClientEmail,
        this.sendDevisCustomizedMessage, this.sendDevisAnswerEmail)
      .then(() => {
        this.isDevisSent = true
        if (!this.devis.has_been_sent) {
          this.devis.has_been_sent = true
        }
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isSendingDevisByEmail = false
        this.sendDevisEmailInProcess = false
      })
    },

    getClientAllMissions () {
      this.isAllMissionsLoading = true
      apiUserConnected.getClientAllMissions(this.token, this.devis.client.id)
        .then((result) => {
          if (result.data.length == 0) {
            this.missionsSelectOptions = [{label: '', value: ''}]
          }
          else if (result.data.length == 1) {
            this.missionsSelectOptions = [{label: result.data[0].name, value: result.data[0].id}]
            this.missionChosen = result.data[0].id
          }
          else {
            var missions_final_array = [{label: '', value: ''}]
            for (var i = 0; i < result.data.length; i++) {
              missions_final_array.push(
                {
                  'label': result.data[i].name,
                  'value': result.data[i].id,
                }
              )
            }
            this.missionsSelectOptions = missions_final_array
          }
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isAllMissionsLoading = false
        })
    },



    // ------------ SETTERS -------------

    modifyDevis () {
      this.modifyDevisInProcess = true
      this.modifyDevisButtonText = "Modification en cours"
      this.modifyDevisButtonStyle = 'secondary'
      apiManagerConnected.modifyDevis(this.token, this.$route.params.devis_pk,
        this.modifyDevisTitle, this.modifyDevisAddress,
        this.modifyDevisDate, this.modifyDevisMessageDevis)
      .then(() => {
        this.getDevis()
        this.isDevisModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyDevisInProcess = false
        this.modifyDevisButtonText = "Modifier ce devis"
        this.modifyDevisButtonStyle = 'outline-primary'
      })
    },
    deleteDevis () {
      this.deleteDevisInProcess = true
      this.deleteDevisButtonText = "Suppression en cours"
      this.deleteDevisButtonStyle = 'secondary'
      apiManagerConnected.deleteDevis(this.token, this.$route.params.devis_pk)
      .then(() => {
        this.getDevis()
        this.isDevisArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteDevisInProcess = false
        this.deleteDevisButtonText = "Archiver ce devis"
        this.deleteDevisButtonStyle = 'outline-danger'
      })
    },

    markDevisAsSent () {
      this.markDevisAsSentInProcess = true
      apiManagerConnected.markDevisAsSent(this.token, this.$route.params.devis_pk)
      .then(() => {
        this.getDevis()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.markDevisAsSentInProcess = false
      })
    },


    // --------------- UNITE DEVIS ---------------------
    addUniteDevis (date, description, quantite, prix_unitaire, remise_ht, tva) {
      this.addUniteDevisInProcess = true
      this.addUniteDevisButtonText = "En cours"
      this.addUniteDevisButtonStyle = 'secondary'
      apiManagerConnected.addUniteDevis(this.token,
        this.$route.params.devis_pk, date, description, quantite, prix_unitaire, remise_ht, tva)
      .then(() => {
        this.getAllDevisUnitesDevis()

        this.newUniteDevis.description = ''
        this.isUniteDevisAddingBigDescription = false
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addUniteDevisInProcess = false
        this.addUniteDevisButtonText = "Ajouter"
        this.addUniteDevisButtonStyle = 'dark'
      })
    },

    showModifyUniteDevis(item) {
      this.modifyUniteDevisId = item.id,
      this.modifyUniteDevisDate = item.date,
      this.modifyUniteDevisDescription = item.description,
      this.modifyUniteDevisQuantite = item.quantite,
      this.modifyUniteDevisPrixUnitaire = item.prix_unitaire,
      this.modifyUniteDevisRemiseHT = item.remise_ht,
      this.modifyUniteDevisTVA = item.tva,

      this.isUniteDevisModifying = true
    },
    modifyUniteDevis () {
      this.modifyUniteDevisButtonText = 'Modification en cours'
      this.modifyUniteDevisButtonStyle = 'secondary'
      this.modifyUniteDevisInProcess = true
      apiManagerConnected.modifyUniteDevis(this.token,
        this.modifyUniteDevisId, this.modifyUniteDevisDate, this.modifyUniteDevisDescription, this.modifyUniteDevisQuantite, this.modifyUniteDevisPrixUnitaire,
        this.modifyUniteDevisRemiseHT, this.modifyUniteDevisTVA)
      .then(() => {
        this.getAllDevisUnitesDevis()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isUniteDevisModifying = false
        this.modifyUniteDevisButtonText = 'Modifier'
        this.modifyUniteDevisButtonStyle = 'outline-primary'
        this.modifyUniteDevisInProcess = false
      })
    },
    deleteUniteDevis (unite_devis_id) {
      this.deleteUniteDevisButtonText = 'Suppression en cours'
      this.deleteUniteDevisButtonStyle = 'secondary'
      this.deleteUniteDevisInProcess = true
      apiManagerConnected.deleteUniteDevis(this.token, unite_devis_id)
      .then(() => {
        this.getAllDevisUnitesDevis()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteUniteDevisButtonText = 'Supprimer'
        this.deleteUniteDevisButtonStyle = 'outline-danger'
        this.deleteUniteDevisInProcess = false
      })
    },
  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}

.no-margin-bottom-table {
  margin-bottom: 0px !important;
}

.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}

</style>
